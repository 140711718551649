
:root {
 
  --color-background: #ffffff;
  --color-text-primary: #000000;
  --color-text-secondary: #ff6b00;
  --color-bg-primary: #15232d;
  --color-secondary-background: #e7f0ff;
  --color-input-background: #fafafa;
  --color-border-color: #E2E8F0;

  /* Guess Colors */
  --color-guess-blue: #A8E6F7;
  --color-guess-green: #82EEAD;
  --color-guess-yellow: #FFEE81;
  --color-guess-red: #f86689;
  --color-guess-border: #F3F3F3;


  --max-width: 1100px;
  --border-radius: 12px;
  --font-noto: 'Noto Sans', sans-serif;
  --black: "900";

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 250, 250, 250;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #121212;
    --color-text-primary: #ffffff;
    --color-text-secondary: #ff6b00;
    --color-bg-primary: #15232d;
    --color-secondary-background: #262626;
    --color-input-background: #1f1f1f;
    --color-border-color: #2c2c2c;


    /* Guess Colors */
    --color-guess-blue: #9ccde6;
    --color-guess-green: #74c6ba;
    --color-guess-yellow: #f0cf79;
    --color-guess-red: #ce5465;
    --color-guess-border: #2a2a2a;


    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: var(--color-background) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.how-to-play {
  background-color: var(--color-secondary-background);
  color: var(--color-text-primary);
}
.text-input {
  background-color: var(--color-input-background);
  caret-color: var(--color-text-primary) !important;
  color: var(--color-text-primary) ;
  /* border-color: var(--color-guess-border); */
  font-family: 'Noto Sans';
  font-weight: 600;
  /* border-color: var(--color-border-color) !important; */
}

.guess-blue {
  background-color: var(--color-guess-blue);
}

.guess-green {
  background-color: var(--color-guess-green);
}

.guess-yellow {
  background-color: var(--color-guess-yellow);
}

.guess-red {
  background-color: var(--color-guess-red);
}

.guess-container {
  border-color: var(--color-guess-border);
}

.guess-container-active {
  border-color: var(--color-text-primary);
}

.primary-text {
  color: var(--color-text-primary);
}

.primary-background {
  background-color: var( --color-background) !important;
}

/* Menu  */
.chakra-menu__menuitem {
  background-color: var(--color-background)!important;
}

.chakra-menu__menu-list {
  background-color: var(--color-background)!important;
  border-color: var(--color-border-color)!important;
}

/* Modal */
.chakra-modal__content {
  background-color: var(--color-background)!important;
  border-color: var(--color-border-color)!important;
  color: var(--color-text-primary)!important;

}

/* Buttons */
.chakra-button {
  background-color: var(--color-background)!important;
  color: var(--color-text-primary)!important;
  border-width: 2px;
  border-color: var(--color-border-color)!important;

}
.chakra-button:hover {
  background-color: var(--color-secondary-background) !important;
  border-color: var(--color-text-primary)!important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* Margin */
.tm-10 {
  margin-top: 10px;
}